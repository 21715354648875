<template>
  <div class="wrap">
    <div class="loading">
      <van-loading />
    </div>
  </div>
</template>

<script>
import {loginByCode} from "@/api/login";
import {setToken} from "@/utils/auth";

let TMP_EKY = 'wx_auth';
export default {
  name: "login",
  data() {
    return {
    };
  },
  created() {
    if (!this.$route.query.code) {
      const currentUrl = document.URL;
      const wxAuthRedirectUrl = process.env.VUE_APP_WX_AUTH_REDIRECT_URL;
      const redirectUrl = encodeURIComponent(wxAuthRedirectUrl + '?url=' + encodeURIComponent(currentUrl));
      window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + process.env.VUE_APP_WX_APP_ID + "&redirect_uri=" + redirectUrl + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirec";
    } else {
      loginByCode(this.$route.query.code)
        .then(res => {
          if (res.error === 0) {
            setToken(res.info.token);
            if (this.$route.query.redirect) {
              this.$router.replace({
                path: this.$route.query.redirect,
              });
            } else {
              this.$router.replace({
                name: 'index',
              });
            }

          } else {
            console.log('登录错误', res.message);
            this.$errorMessage(res.message)
          }
        })
        .catch(error => {
          console.log('登录请求错误', error);
        })
    }
  }
}
</script>

<style lang="less" scoped>
  .loading{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
